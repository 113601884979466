import { css } from '../../styles';

//the object bodyLayout are used to generate css for inline injection by scoponok
export const bodyLayout = {
  variants: {
    version: {
      imageLeft: {
        //x- and y-axis tokens have switched places on the horizontal teaser
        '--_b-content-top-inset': 'var(--brick-space-teaserContentXInsetM)',
        '--_b-content-right-inset': 'var(--brick-space-teaserContentYInsetM)',
        '--_b-content-bottom-inset': 'var(--brick-space-teaserContentXInsetM)',
        '--_b-content-left-inset': 'var(--brick-space-teaserContentYInsetM)',

        columnGap: '$teaserStackAM',
        display: 'grid',
        gridTemplateColumns: 'calc(34% + 3%) 1fr',
        gridFlow: 'row',
        position: 'relative',
        '& figure': {
          gridColumn: '1/1',
        },
        '& .body': {
          boxSizing: 'border-box',
          gridColumn: '2/-1',
        },
        '[data-theme="alfa"] &': {
          '& .body .premium-icon': {
            display: 'none',
          },
        },
        '&.mirror': {
          gridTemplateColumns: '1fr calc(34% + 3%)',
          '& figure': {
            gridColumn: '2/-1',
            gridRow: '1/1',
          },
          '& .body': {
            gridColumn: '1/1',
          },
        },
        '&.marker': {
          gap: 0,
          columnGap: 0,
        },
        '&.marker .body': {
          gap: 0,
        },
        '&.mirror.marker': {
          '--_b-content-right-inset': 0,
        },
        '[data-theme="charlie"]:has(.premium) &': {
          '--_b-content-right-inset': 0,
        },
        '@bp532': {
          '--_b-content-top-inset': 'var(--brick-space-teaserContentXInsetL)',
          '--_b-content-right-inset': 'var(--brick-space-teaserContentYInsetL)',
          '--_b-content-bottom-inset':
            'var(--brick-space-teaserContentXInsetL)',
          '--_b-content-left-inset': 'var(--brick-space-teaserContentYInsetL)',

          columnGap: '$teaserStackAL',
          '& .body': {
            //x- and y-axis tokens have switched places on the horizontal teaser
            gridRowGap: '$teaserStackBL',
          },
          '&.mirror': {
            '--_b-content-right-inset': 'var(--brick-space-teaserBoxInsetL)',
          },
          '&.marker': {
            columnGap: 0,
          },
          '&.marker .body': {
            gap: 0,
          },
          '&.mirror.marker': {
            '--_b-content-right-inset': 0,
          },
        },
      },
    },
  },
};

export const baseBodyStyle = css({
  textDecoration: 'none',
  color: 'inherit',
  overflow: 'visible',
  boxSizing: 'border-box',

  '& > *': {
    minWidth: '0px', // setting this to avoid minwidth auto
  },

  '& brick-pillbox': {
    flexWrap: 'nowrap',
  },
});

export const bodyStyle = css({
  '--_b-content-top-inset': 'var(--brick-space-teaserContentYInsetM)',
  '--_b-content-right-inset': 'var(--brick-space-teaserContentXInsetM)',
  '--_b-content-bottom-inset': 'var(--brick-space-teaserContentYInsetM)',
  '--_b-content-left-inset': 'var(--brick-space-teaserContentXInsetM)',

  display: 'grid',
  gridTemplateColumns: '1fr',
  gridTemplateRows: 'auto',
  gridAutoRows: '1fr auto',
  textDecoration: 'none',
  color: 'inherit',
  overflow: 'visible',
  rowGap: '$teaserStackAM',
  '& .body': {
    container: 'teaser-content / inline-size',
    display: 'grid',
    gridTemplateRows: 'auto',
    gridTemplate: 'auto / 1fr',
    columnGap: '0',
    rowGap: '$teaserStackBM',
    paddingTop:
      'var(--b-teaser-padding-content-top, var(--_b-content-top-inset))',
    paddingRight:
      'var(--b-teaser-padding-content-right, var(--_b-content-right-inset))',
    paddingBottom:
      'var(--b-teaser-padding-content-bottom, var(--_b-content-bottom-inset))',
    paddingLeft:
      'var(--b-teaser-padding-content-left, var(--_b-content-left-inset))',
  },
  '& .top-pills': { display: 'var(--brick-teaser-display-top-pills)' },
  '& .body > *': {
    minWidth: '0px', // setting this to avoid minwidth auto
  },
  '& .body brick-pillbox': {
    flexWrap: 'nowrap',
  },
  '& .body:has(brick-pillbox)': {
    gridTemplateRows: '1fr auto',
  },
  '@bp532': {
    '--_b-content-top-inset': 'var(--brick-space-teaserContentYInsetL)',
    '--_b-content-right-inset': 'var(--brick-space-teaserContentXInsetL)',
    '--_b-content-bottom-inset': 'var(--brick-space-teaserContentYInsetL)',
    '--_b-content-left-inset': 'var(--brick-space-teaserContentXInsetL)',
    rowGap: '$teaserStackAL',
    '& .body': {
      rowGap: '$teaserStackBL',
    },
  },

  variants: {
    version: {
      imageLeft: {
        ...bodyLayout.variants.version.imageLeft,
      },
    },
    marker: {
      true: {
        gap: 0,
        '& .body': {
          padding: 0,
          gap: 0,
          justifyContent: 'flex-start',
        },
        '@bp532': {
          gap: 0,
          '& .body': {
            padding: 0,
            gap: 0,
            columnGap: 0,
          },
        },
      },
    },
  },
  defaultVariants: {
    version: 'default',
    marker: false,
  },
  '[data-group="ghost"] &': {
    '@container (inline-size >= 920px)': {
      display: 'grid',
      gap: '$teaserStackAL',
      gridTemplateColumns: '1fr',
      '&:has(figure)': {
        gridTemplateColumns: '1fr 2fr',
      },
    },
  },
});

export const pillContainer = css({
  placeItems: 'end',
  display: 'flex',
  justifyContent: 'space-between',
  paddingTop: 'calc($teaserStackCM - $teaserStackBM)',
  '@bp532': {
    paddingTop: 'calc($teaserStackCL - $teaserStackBL)',
  },
  '& [data-version="plus"]': {
    display: 'flex',
    justifyContent: 'flex-end',
    flexGrow: '1',
  },
  variants: {
    pills: {
      true: {
        alignItems: 'center',
        height: 'fit-content',
        alignSelf: 'end',
      },
      false: {},
    },
    marker: {
      true: {
        paddingTop: 0,
        '@bp532': {
          paddingTop: 0,
        },
      },
    },
    theme: {
      alfa: {},
      bravo: {},
      charlie: {},
      nettavisen: {},
      alt: {},
    },
  },
  deafaultVariants: {
    marker: false,
    pills: false,
  },
  compoundVariants: [
    {
      theme: 'charlie',
      pills: false,
      css: {
        gridColumn: '2',
      },
    },
    {
      theme: 'charlie',
      pills: true,
      css: {
        gridColumn: '1 / 3',
      },
    },
  ],
});
